jQuery(document).ready(function ($) {
    $(".js-to-anchor").click(function (event) {
        event.preventDefault();
        sectionId = $(this).attr('href');
        sectionTop = $(sectionId).offset().top;
        $("html, body").animate({ scrollTop: sectionTop }, 500);
    });

    // Inline label forms: Add active class if input has value
    $('.gform_wrapper input[type=text], .gform_wrapper input[type=tel], .gform_wrapper input[type=email]').each(function () {
        if ($(this).val().length) {
            $(this).closest('.gfield').addClass('active');
        }
    });

    // Inline label forms: Add active class on focus
    $('.gform_wrapper input[type=text], .gform_wrapper input[type=tel], .gform_wrapper input[type=email]').on('focus', function () {
        $(this).closest('.gfield').addClass('active');
    });

    // Inline label forms: Remove active class on blur
    $('.gform_wrapper input[type=text], .gform_wrapper input[type=tel], .gform_wrapper input[type=email]').on('blur', function () {
        if (!$(this).val().length || $(this).val().includes('_')) {
            $(this).closest('.gfield').removeClass('active');
        }
    });

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    window.addEventListener("scroll", reveal);

    var volumeDesktopAnimation = bodymovin.loadAnimation({
        container: document.getElementById('leadmachine-animation-volume-desktop'),
        path: blank.template_url + '/library/json/leadmachine-animation-volume-desktop.json',
        renderer: 'svg',
        loop: true,
        autoplay: true
    });
    var volumeMobileAnimation = bodymovin.loadAnimation({
        container: document.getElementById('leadmachine-animation-volume-mobile'),
        path: blank.template_url + '/library/json/leadmachine-animation-volume-mobile.json',
        renderer: 'svg',
        loop: true,
        autoplay: true
    });
    var frequencyDesktopAnimation = bodymovin.loadAnimation({
        container: document.getElementById('leadmachine-animation-frequency-desktop'),
        path: blank.template_url + '/library/json/leadmachine-animation-frequency-desktop.json',
        renderer: 'svg',
        loop: true,
        autoplay: true
    });
    var frequencyMobileAnimation = bodymovin.loadAnimation({
        container: document.getElementById('leadmachine-animation-frequency-mobile'),
        path: blank.template_url + '/library/json/leadmachine-animation-frequency-mobile.json',
        renderer: 'svg',
        loop: true,
        autoplay: true
    });
});